.App {
  font-family: sans-serif;
  text-align: center;
}

/* #cart {
  position: fixed;
  width: 20%;
  right: 0;
  top: 5%;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
  z-index: 1;
  overflow: scroll;
} */

.carousel-item img {
  max-height: 500px; 
  object-fit: cover;
}

.container, .card, .cart-item {
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
  padding: 1rem;
}

.item-info-details {
  margin-top: 15px;
}

.item-info-details li {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.material-symbols-outlined {
  font-weight: bold;
  margin-right: 5px;
}

.cart-item img {
  max-width: 128px;
  max-height: 128px;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.form-group input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group input[type="number"] {
  max-width: 50px;
}